.product-price{
	&__status{
		display: block;
		font-size: 14px;
		font-weight: 400;
		&_available{
			color: #5cb85c;
		}
	}
	&__price{
		font-weight: 700;
		font-size: 24px;
		line-height: 26px;
		color: $color-text;
	}
	&__old_price{
		display: inline-block;
		color: #999;
		font-size: 14px;
	}
	
}

@media(max-width: 575px){
	.product-price{
		padding-bottom: 10px;
		&__price{
			font-size: 20px;
		}
	}
}