.back-to-catalog{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 174px;
	padding: 8px 0;
	margin: 0px 0 26px;
    border: 1px solid #b9b9b9;
	background-color: #fff;
	color: #b9b9b9;
	font-size: 12px;
	line-height: 13px;
	transition: .3s;

	&:hover{
		color: #fff;
		background-color: $color-general;
		border-color: $color-general;
		> svg{
			fill: #fff;
		}
	}
	> svg{
		margin: 0 6px 2px 0;
		fill: #b9b9b9;
		transition: .3s;
	}
}