.sort-parameters{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0 10px;
	&__order-by{
		color: #999;
		font-size: 15px;
		line-height: 21px;
		select{
			border: none;
			outline: none;
			color: #999;
			font-size: 15px;
			line-height: 21px;
			appearance: none;
			background: transparent;
			transition: $time;
			&:hover{
				color: $color-general;
			}
		}
	}
	&__layout-types{
		display: flex;
		button{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 30px;
			height: 30px;
			margin: 0 0 0 5px;
			border: 1px solid #e5e5e5;
			background-color: #fff;
			outline: none;
			transition: .3s;
			cursor: pointer;
			&.active{
				border-color: $color-general;
				> svg{
					fill: $color-general;
				}
			}
			> svg{
				transition: .3s;
				fill: #999;
			}
		}
	}
}