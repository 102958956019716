.show-after-load{
	visibility: hidden;
}
.theme-more-button{
	border: 1px solid #D6D6D6;
	transition: $time;
	a{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 50px;
		font-weight: 700;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 0.8px;
		text-transform: uppercase;
		color: $color-general;
		transition: $time;
		
	}
	&:hover{
		background-color: $color-general;
		border-color: $color-general;
		a{
			color: #fff;
		}
	}
}

.theme-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
	p{
		font-weight: 900;
		font-size: 40px;
		line-height: 47px;
		color: $color-primary;
		padding: 0 40px;
    	background: #fff;
    	margin-top: 10px;
	}
	span{
		display: block;
		position: relative;
		background-color: $color-general;
		width: 6px;
		height: 35px;
		&::before, &::after{
			content: '';
			position: absolute;
			width: 6px;
			height: 22px;
			background-color: $color-general;
			bottom: 0;
		}
		&::before{
			left: -12px;
		}
		&::after{
			right: -12px;
		}
	}
}

.theme-btn{
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #0E53A0;
	font-weight: bold;
	font-size: 13px;
	line-height: 15px;
	text-align: center;
	letter-spacing: 0.742857px;
	text-transform: uppercase;
	color: #FFFFFF;
}

.theme-addresses-btn{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: $color-general;
	border: 1px solid transparent;
	transition: $time;

	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.8px;
	text-transform: uppercase;
	color: #FFFFFF;

	svg{
		width: 18px;
		height: 18px;
		fill: #FFFFFF;
		margin-right: 8px;
		transition: $time;
	}
	&:hover{
		cursor: pointer;
		background-color: transparent;
		border-color: $color-general;
		color: $color-general;
		svg{
			fill: $color-general;
		}
	}
}

.theme-social{
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $color-general;
	border: 1px solid $color-general;
	width: 38px;
	height: 38px;
	float: left;
	margin: 10px 10px 0 0;
	transition: $time;
	svg{
		fill: #fff;
		width: 20px;
		height: 20px;
		transition: $time;
	}
	&:hover{
		background-color: transparent;
		svg{
			fill: $color-general;
		}
	}
	&__vk{

	}
	&__insta{

	}
}

.slick-arrow{
	display: flex;
	width: 55px;
	height: 55px;
	justify-content: center;
	align-items: center;
	background-color: $color-primary;
	svg{
		width: 7.5px;
		height: 14px;
		fill: #fff;
	}
	&:hover{
		cursor: pointer;
	}
}

// .slick-dots{
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     height: 35px;
//     li{
//     	width: 12px;
//     	height: 12px;
//     	display: block;
//     	margin: 0 1px;
//     	button{
//     		display: block;
// 	    	outline: none;
// 	    	background-color: transparent;
// 			border-color: $color-primary;
// 	    	border-radius: 50%;
// 	    	font-size: 0;
// 	    	width: 12px;
// 	    	height: 12px;
// 	    }
//     }
    
//     .slick-active{
// 		button{
// 	    	background-color: $color-primary;
// 	    	border: 1px solid transparent;
//     	}
//     }
// }

.slick-dots{
	// width: 100%;
    width: 50%;
    margin: 0 auto;
	height: auto;
	z-index: 999;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	li{
		margin-right: 5px;
		margin-bottom: 5px;
		border: 2px solid transparent;
		button{
			display: block;
			width: 10px;
			height: 10px;
			border: 1px solid #FFFFFF;
			opacity: 0.3;
			font-size: 0;
			background: none;
		}
		&.slick-active{
		    border: 4px solid #FFFFFF;
			margin-right: 5px;
			button{
				border-color: transparent !important;
				width: 6px;
				height: 6px;
			}
		}
	}
}


@media (max-width: 576px) {
	.theme-title{
		margin-bottom: 14px;
	}
	.theme-title p{
	    font-size: 24px;
    	line-height: 30px;
    	margin-top: 0;
    	text-align: center;
	}
	.theme-title span{
	   	width: 4px;
    	height: 22px;
	}
	.theme-title span::before, .theme-title span::after{
		width: 4px;
		height: 11px;
	}
	.theme-title span::before{
		left: -7px;
	}
	.theme-title span::after{
		right: -7px;
	}
}