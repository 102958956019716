.vechicle-card{
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    &__logo{
        display: block;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 165px;
        width: 100%;
        left: 0;
        top: 0;
        filter: grayscale(100%);
        opacity: 0.2;
        z-index: -1;
        transition: $time;
    }
    &__thumb{
        object-fit: contain;
        margin: 0 auto;
        max-width: 350px;
    }
    .theme-btn{
        background-color: transparent;
        color: $color-general;
        border:1px solid $color-border;
        width: 170px;
        height: 40px;
        margin: 28px auto 0;
        transition: $time;
    }
    &:hover{
        .vechicle-card__logo{
            filter: grayscale(0%);
            opacity: 1;
        }
        .theme-btn{
            border-color: $color-general;
            background-color: $color-general;
            color: #fff;
        }
    }
}

@media (max-width: 991px) {
    .vechicle-card{
        height: 415px;
        margin-bottom: 45px;;
    }

}

@media (max-width: 425px) {
    .vechicle-card{
        height: 390px;
        margin-bottom: 40px;
    }
    .vechicle-card__thumb{
        max-width: 270px;
        max-height: 170px;
    }
    .index-row.vechicle .vechicle-container{
        padding: 28px 15px 8px;
    }
}