.header {
    height: 125px;
    background: rgb(228,232,244);
    background: linear-gradient(180deg,  rgba(208,214,231,1) 60%, rgba(228,232,244,1) 60%);
    .container{height: 100%;}
    .mobile-search-trigger{
        display: none;
        height: 100%;
        width: 50px;
        background-image: url('../images/zoom.png');
        background-position: center;
        background-size: 19px;
        background-repeat: no-repeat;
        position: absolute;
        right: 40px;
    }
    &-wrapper{
        height: 100%;
        display: flex;
        flex-direction: row;
        &__logo{
            background-color: $color-general;
            width: 100px;
            height: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: row;
            svg{
                fill: #fff;
                width: 90px;
                height: 100px;
            }
        }
        &__nav{
            flex: 1 0 auto;
            &-top{
                display: flex;
                flex-direction: row;
                height: 75px;
                .nav-info{
                    width: 170px;
                    padding: 20px;
                    border-right: 1px solid #BAC0D0;
                    height: 100%;
                    p{
                        font-size: 14px;
                        line-height: 18px;
                        color: $color-placeholder;
                    }
                }
                .main-menu-wrapper{
                    flex: 1 0 auto;
                    height: 100%;
                    ul{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 100%;
                        justify-content: space-between;
                        padding-left: 23px;
                        li{
                            a{
                                border-top: 3px solid transparent;
                                // padding: 29px 0;
                                font-weight: 700;
                                font-size: 12px;
                                line-height: 14px;
                                text-align: right;
                                letter-spacing: 0.685714px;
                                text-transform: uppercase;
                                color: #595E6C;
                                margin-right: 0px;

                                &:hover{
                                    border-color: $color-general;
                                    color: #333333;
                                }
                            }
                        }
                        li.has-nav-dropdown {
                            position: relative;
                            display: flex;

                            .nav-dropdown-list {
                                display: none;
                                position: absolute;
                                top: 100%;
                                width: 275px;
                                background-color: #FFFFFF;
                                /*-webkit-box-shadow: 0 20px 40px 0 rgba(174, 89, 98, 0.2);*/
                             /*   box-shadow: 0 20px 40px 0 rgba(174, 89, 98, 0.2);*/
                                /*overflow: hidden;*/
                                z-index: 1000;
                                border: 1px solid #DDDDDD;
                                border-top: none;
                                padding-left: 0;

                                >li {
                                    a {
                                        display: block;
                                        text-align: left;
                                        padding: 18px 10px;
                                        font-weight: 700;
                                        font-size: 12px;
                                        line-height: 14px;
                                        letter-spacing: 0.685714px;
                                        text-transform: uppercase;
                                        color: #595E6C;
                                        margin-right: 30px;
                                        margin: 0;
                                        border-bottom: 1px solid #DDDDDD;
                                        background: #fff;

                                        &:hover {
                                            background-color: #0E53A0;
                                            color: #fff;
                                        }
                                    }
                                }
                            
                            }

                            &:hover {
                                .nav-dropdown-list {
                                    display: block;
                                }
                            }
                        }
                    }
                }
                .mobile-menu-button{
                    display: none;
                    width: 25px;
                    height: 30px;
                    margin-right: 15px;
                    position: relative;
                    z-index: 9;
                    span {
                        display: block;
                        width: 100%;
                        height: 2px;
                        position: absolute;
                        top: 50%;
                        background-color: $color-general;
                        transform: translate(0, -50%);
                        transition: opacity 0.2s 0.2s;
                    }
                    &::before,
                    &::after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 2px;
                        position: absolute;
                        background-color: $color-general;
                        transition: transform 0.2s, top 0.2s 0.2s, bottom 0.2s 0.2s;
                    }
                    &::before {
                        top: 6px;
                    }
                    &::after {
                        bottom: 6px;
                    }
                    &.opened{
                        span {
                            opacity: 0;
                            transition: opacity 0.2s;
                        }
                        &::before,
                        &::after {
                            background-color: $color-general;
                        }
                        &::before {
                            top: calc(50% - 1px);
                            transform: rotate(45deg);
                        }
                        &::after {
                            bottom: calc(50% - 1px);
                            transform: rotate(-45deg);
                        }
                        &::before,
                        &::after {
                            transition: top 0.2s, bottom 0.2s, transform 0.2s 0.2s;
                        }
                    }
                }
            }
            &-bottom{
                display: flex;
                flex-direction: row;
                .catalog-button{
                    height: 50px;
                    position: relative;
                    z-index: 999;
                    &__link{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 170px;
                        height: 100%;
                        border-right: 1px solid #BAC0D0;
                        &-lines{
                            width: 13px;
                            height: 2px;
                            display: block;
                            position: relative;
                            top: -1px;
                            background-color: $color-general;

                            &::after, &::before{
                                content: '';
                                display: block;
                                position: relative;
                                width: 13px;
                                height: 2px;
                                background-color: $color-general;

                            }
                            &::before{
                                top: -5px;
                            }

                            &::after{
                                bottom: -3px;
                            } 
                        }
                        &-title{
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 16px;
                            letter-spacing: 0.8px;
                            text-transform: uppercase;
                            color: $color-text;
                            margin-left: 8px;
                        }
                    }
                    &:hover{
                        .catalog-button__link{
                            background-color:$color-primary;
                        }
                        .catalog-button__link-title{
                            color: #fff;
                        }
                        .catalog-menu{
                            display: block;
                        }
                    }
                }
                .catalog-menu{
                    display: none;
                    position: absolute;
                    left: -1px;
                    width: 360px;
                    border: 1px solid #DDDDDD;
                    border-top: none;
                    background-color: #fff; 
                    &__item{
                        border-bottom: 1px solid #DDDDDD;
                        &-link{
                            display: block;
                            padding: 15px 15px;
                            span{
                                font-size: 16px;
                                line-height: 22px;
                                color: #333333;
                            }
                            &:hover{
                                background-color: $color-general;
                                span{
                                    color: #fff;
                                }
                            }
                        }
                        &:last-child{
                            border: none;
                        }
                    }
                }
                .search-form{
                    flex: 1 0 auto;
                    position: relative;
                    border-right: 1px solid #BAC0D0;
                    input{
                        border: none;
                        outline: none;
                        height: 100%;
                        width: 100%;
                        background-color: transparent;
                        padding: 0 45px 0 20px;
                        font-size: 14px;
                        line-height: 16px;
                        color: $color-placeholder;
                    }
                    button{
                        width: 16px;
                        height: 16px;
                        background: none;
                        outline: none;
                        border: none;
                        right: 15px;
                        top: calc(50% - 8px);
                        position: absolute;
                        background-image: url('../images/zoom.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                    &__ajax-search{
                        padding: 10px 20px 0;
                        
                        > ul{
                            display: none;
                            border: 1px solid #ccc;
                            background-color: #fff;
                            position: absolute;
                            z-index: 200;
                            left: 0;
                            top: 100%;
                            width: calc(100% + 2px);
                            max-height: 500px;
                            overflow-y: scroll;
                            margin-left: -1px;
                            li{
                                a, span{
                                    display: block;
                                    padding: 8px 20px;
                                    color: #9b9b9b;
                                    font-size: 15px;
                                    font-weight: 600;
                                    line-height: 21px;
                                    background-color: #f5f5f5;
                                    transition: .2s;
                                    &:hover{
                                        color: $color-general;
                                    }
                                }
                            }

                            ul{
                                padding: 2px 0;
                                li{
                                    a{
                                        
                                        padding: 5px 20px;
                                        color: #373a3c;
                                        font-size: 16px;
                                        font-weight: 400;
                                        line-height: 22px;
                                        background-color: transparent;
                                        &:hover{color: $color-general;}
                                    }
                                }
                            }
                        }
                    }
                }
                .shops-addresses{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0 26px;
                    border-right: 1px solid #BAC0D0;
                    transition: $time;
                    svg{
                        width: 16px;
                        height: 16px;
                        fill: $color-general;
                        margin-right: 5px;
                        margin-top: -3px;
                        transition: $time;
                    }
                    span{
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 16px;
                        letter-spacing: 0.8px;
                        text-transform: uppercase;
                        color: $color-text;
                        transition: $time;
                    }
                    &:hover{
                        background-color: $color-primary;
                        span{
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}



.follower-modals{
    position: fixed;
    right: -1px;
    top: 20vh;
    z-index: 999;
    background-color: #d0d6e7;
    border-radius: 5px 0 0 5px;
    transition: .8s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    border: 1px solid #203650;
    &__item{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 15px;
        cursor: pointer;
    }
    &__icon{
        padding-right: 15px;
        svg{
            width: 20px;
            height: 20px;
            fill: #203650;
        }
    }
    &__text{
        p{
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.685714px;
            text-transform: uppercase;
            color: #203650;
        }
    }
    &:hover{
        right: -1px !important;
    }
    &.hide{
        right: -125px;
    }
}


@media (max-width: 1199px) {
    .main-menu-wrapper, .header-wrapper__nav-bottom{
        display: none;
    }
    header{
        background: #e4e8f4 !important;
        height: 60px !important;
    }
    .header-wrapper{
        margin: 0 -15px;
    }
    body.opened{
        padding-right: 0;
    }
    .header-wrapper__nav-top{
        height: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .header-wrapper__logo svg{
        width: 100px;
        height: 45px;
    }
    .header-wrapper__nav-top .nav-info{
        padding: 12px 10px;
        border: none;
    }
    .mobile-menu-button, .mobile-search-trigger{
        display: block !important;
    }
}

@media (max-width: 390px) {
    .header-wrapper__logo svg {
        width: 75px;
        height: 35px;
    }
    .header-wrapper__nav-top .nav-info{
        padding: 10px 30px 0 10px;
    }
    .header-wrapper__nav-top .nav-info p{
        font-size: 12px;
        line-height: 15px;
    }
    .header-wrapper__nav-top{
        justify-content: space-between;
    }
    header{
        height: 50px !important;
    }
    .mobile-menu, .mobile-menu .sub-menu, .mobile-search-wrapper {
        top: 50px !important;
    }
    .header-wrapper__logo{
        width: 115px;
    }
    .mobile-menu__footer .theme-addresses-btn{
        width: 100% !important;
    }
}


            .header-wrapper__nav-top .main-menu-wrapper ul li a{
              padding-right: 10px !important;
            }
 