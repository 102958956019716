.extra-products-slider{
	padding: 30px 0;
	&.active &{
		&__slider-header{
			padding: 0 10px 15px;
			.nav-buttons{
				display: flex;
			}
		}
	}
	.slick-dots li button{
		border-color: #dedede;
		opacity: 1;
	}
	.slick-dots li.slick-active{
		border-color: $color-general;
	}
	.slick-dots{
	    margin-top: -25px;
   	 	margin-bottom: 0px;
	}
	&__slider-header{
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		&-title{
			display: block;
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			color: $color-primary;
		}
		.nav-buttons{
			display: none;
			justify-content: space-between;
			width: 75px;
			a{
				display: flex;
				justify-content: center;
				align-items: center;
				width: 35px;
				height: 35px;
				border: 1px solid #e5e5e5;
				background-color: #fff;
				transition: $time;
				&:hover{
					border-color: $color-general;
					background-color: $color-general;
					> svg{
						fill: #fff;
					}
				}
				&.slick-hidden{
					display: none;
				}
				&.next{
					> svg{
						transform: rotate(180deg);
					    margin-left: 3px;
					}
				}
				> svg{
					transition: $time;
					width: 10.5px;
    				height: 14px;
    				fill: #a49e9e;
				}
			}
		}
	}
	&__slider-body{
		overflow: hidden;
		.slick-list{
			margin: 0 -15px;
			overflow-y: visible;
			overflow-x: hidden;
			padding: 0 10px;
			.slick-track{
				min-width: 100%;
			}
		}
		.slick-slide{
			margin: 0 15px;
		}
	}
	&__no-slider{
		padding: 15px 0;
	}
}


@media(max-width: 768px){
	.extra-products-slider{
		padding: 15px 0;
		&__slider-header{
			flex-direction: column;
			align-items: flex-start;
			&-title{
				font-size: 22px;
				padding-bottom: 5px;
			}
		}
	}
	.extra-products-slider.active .extra-products-slider__slider-header .nav-buttons{
		display: none;
	}
}