.index-slider{
	height: 519px;
	background-color: $color-primary;
	position: relative;
	overflow: hidden;
	&__slider{
		height: auto;
		overflow: hidden;
		&-slide{
			position: relative;
			padding: 71px 0;
			overflow: hidden;
		}
		&-image{
			background-size: cover;
			width: 50vw;
			height: 100%;
			position: absolute;
		    right: -10.2%;
			top: 0;
			background-position: left bottom;
		}
		&-product{
		    margin-top: -24px;
    		margin-left: -45px;
		    z-index: 999;
    		position: relative;
			img{
				width: 100%;
				height: fit-content;
			}
		}
		&-content{
			position: absolute;
		    z-index: 9999;
			bottom: 60px;
			right: 35px;
			p{
				font-weight: 300;
				font-size: 34px;
				line-height: 46px;
				color: #FFFFFF;
				letter-spacing: -0.5px;
			    padding: 38px 0px 12px;
			}
			b{
				font-weight: 900;
				font-size: 40px;
				line-height: 47px;
				color: #FFFFFF;
				display: inline-block;
    			margin-bottom: 40px;
			}
			a{
				width: 200px;
				height: 55px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $color-general;
				font-weight: 700;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: 0.8px;
				text-transform: uppercase;
				color: #FFFFFF;
				border: 1px solid transparent;
				transition: $time;
				&:hover{
					background-color: transparent;
					border-color: #fff;
				}
			}
			// &::before{
			// 		content: '';
			//     font-family: "Roboto", sans-serif;
			//     overflow: hidden;
			//     //content: 'АКЦИЯ АКЦИЯ';
			//     position: relative;
			//     display: block;
			//     font-style: italic;
			//     font-weight: 900;
			//     font-size: 78px;
			//     letter-spacing: 3px;
			//     color: #E3E5E9;
			//     mix-blend-mode: normal;
			//     background-color: #FFFFFF;
			//     width: 600px;
			//     height: 55px;
			//     line-height: 56px;
			//     text-align: right;
			//     top: 7px;
			//     z-index: 99;
			//     left: -199px;
			// }
		}
	}
	&__nav{
		width: 1170px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		position: relative;
		bottom: 55px;
		z-index: 9991;
		.slick-arrow{
			border: 1px solid #ffffff60;
			transition: $time;
			&.nextArrow{
				margin-left: -1px;
			}
			&:hover{
				background-color: $color-general;
				border-color: $color-general;
			}
		}
	}
	.slick-list, .slick-track{
		height: auto;
	}
	.slick-track {
		.slick-slide {
			>div {
				height: auto;
  			line-height: 0;
			}
		}
	}
	.slick-dots{
		position: absolute;
		bottom: 10px;
	    right: 0;
			left: 0;
			
			li {
				margin-right: 5px;
  			border: 3px solid #0E53A0;
			}

			li.slick-active {
				border: 3px solid #0E53A0;
				margin-right: 5px;
				
				button {
					border-color: #0E53A0 !important;
					width: 8px;
					height: 8px;
				}
			}
	}
}

@media (max-width: 1199px) {
	.index-slider__slider-slide{
		padding: 70px 15px;

		.container {
			max-width: 100%;
			padding: 0;
			margin-left: 0px;
			margin-right: 0px;
		}
	}
}

@media (max-width: 991px) {
	.index-slider{
		height: 469px;
	}
	.index-slider__slider-slide{
		padding: 20px 15px;
		height: 470px;
	}
	.index-slider__slider-product{
		margin: 0;
	    height: 100%;
	    display: flex;
	    align-items: center;
	}
	.index-slider__slider-image{
		width: 45vw;
	    background-position: center;
	}
	.index-slider__slider-product img{
		max-height: 300px;
		height: fit-content;
    width: 100%;
	}
	.index-slider__nav{
		display: none;
	}
}

@media (max-width: 767px) {
	.index-slider__slider-image{
		display: none;
	}
	.index-slider, .index-slider__slider-slide{
		height: auto;

	}
	 .index-slider__slider-slide{
		padding: 10px 15px 55px;
	}
	.index-slider__slider-content p {
	    font-size: 24px;
	    line-height: 30px;
	    padding: 28px 0px 0px;
	}
	.index-slider__slider-content b {
	    font-size: 28px;
	    margin-bottom: 6px;
	}
	.index-slider__slider-content a {
	    width: 170px;
	    height: 40px;
	}
	.index-slider__slider-content::before{
	    font-size: 64px;
        width: 520px;
		height: 45px;
		line-height: 48px;
	}
}

@media (min-width: 768px) and (max-width: 991px){
  .index-slider__slider-product img {
    max-height: 470px;
  }
}

@media (max-width: 576px) {
	.index-slider__slider-content::before{
		width: 290px;
		left: -50%;
	}
}

@media (max-width: 425px) {
	.index-slider__slider-product img{
		//width: 125px;
		height: auto;
	    margin: 0 auto;
	}
	.index-slider__slider-content::before{
		display: none;
	}
	.index-slider__slider-slide::before{
			display: none;
			content: '';
	    font-family: "Roboto", sans-serif;
	    overflow: hidden;
	    //content: 'АКЦИЯ АКЦИЯ';
	    position: relative;
	    font-style: italic;
	    font-weight: 900;
	    font-size: 32px;
	    letter-spacing: 3px;
	    color: #E3E5E9;
	    mix-blend-mode: normal;
	    background-color: #FFFFFF;
	    width: 135px;
	    height: 23px;
	    line-height: 25px;
	    text-align: right;
	    top: 0px;
	    left: 50%;
	    right: -104px;
	}
	.index-slider__slider-product{
		padding-top: 0px;
	}
	.index-slider__slider-content p {
	    font-size: 16px;
	    line-height: 20px;
	    padding: 20px 0px 0px;
	}
	.index-slider__slider-content b {
	    font-size: 18px;
	    margin-bottom: 0px;
	}
	.index-slider__slider-content a{
		width: 115px;
		height: 28px;
		font-size: 12px;
	}
	.index-slider__slider-slide {
	    padding: 10px 15px 45px;
	}
}



