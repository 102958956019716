.footer{
	border-top: 2px solid $color-border;
	padding: 38px 0 0px;
	&-logo{
		svg{
			width: 65px;
            height: 100px;
			fill: $color-general;
			// margin-bottom: 10px;
			margin-right: 20px;
			float: left;
		}
		p{
			font-size: 14px;
			line-height: 18px;
			color: $color-placeholder;
			padding-bottom: 10px;
			// padding-right: 90px;
		}
	}
	&-nav{
		p{
			font-weight: 700;
			font-size: 16px;
			line-height: 19px;
			color: $color-general;
			margin-bottom: 8px;
		}
		ul{
			li{
				margin-bottom: 5px;
				a{
					display: inline-block;
					padding: 5px 0;
					font-size: 15px;
					line-height: 15px;
					color: $color-placeholder;
					transition: $time;
					border-bottom: 1px solid transparent;
					&:hover{
						border-color: $color-general;;
					}
				}
			}
		}
	}
	&-addresses{
		width: 100%;
		height: 50px;
		margin-right: 0px;

	}
	&-bottom{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		border-top: 1px solid $color-border;
	    margin-top: 42px;
    	padding: 15px 0 18px;
		&__info{
			margin-right: 15px;
			span{
				font-size: 13px;
				color: #B6B6B6;
				margin-right: 38px;
			}
			a{
				font-size: 13px;
				color: #B6B6B6;
				text-decoration: underline;
			}
		}
		&__made{
			a{
				font-size: 13px;
				color: #B6B6B6;
				svg{
					margin-left: 5px;
					width: 108px;
					height: 23px;
				}
			}
		}
	}
}
.social{
	display:flex;
	justify-content: space-between;
}

@media (max-width: 991px) {
	// .md-hidden{
	// 	display: none;
	// }
}
.avito_img{
	height: 38px;
	margin: 10px 0 0 0;
	border:1px solid #0E53A0;
}
.avito_img:hover{
	.st0{
		fill:#FFFFFF;
	}
	.st1{
		fill:#0E53A0;
	}
}

@media (max-width: 767px) {
	.footer-addresses{
		width: 238px;
		margin-top: 15px;
	}
	.footer{
		padding-top: 18px;
	}
	.footer-logo{
		margin-bottom: 15px;
	}
	.social{
		width: 238px;
	}
}

@media (max-width: 575px) {
	.footer-bottom__info span{
		margin-right: 0;
	    margin-bottom: 5px;
	}
	.footer-bottom__info{
		margin-right: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 10px;
	}
	
}