* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    min-height: 100%;
    height: 100%;
}

body {
    min-width: 320px;
    height: 100%;
    margin: 0;
    padding: 0;
	font-family: $font;
	&.opened {
		// position: fixed;
		// left: 0;
		// right: 0;
		// top: 0;
        padding-right: 17px;
		overflow: hidden;
	}
}

::-webkit-input-placeholder { color: $color-placeholder; }
::-moz-placeholder          { color: $color-placeholder; }
:-moz-placeholder           { color: $color-placeholder; }
:-ms-input-placeholder      { color: $color-placeholder; }

ol, ul {
    padding: 0;
    margin: 0;
}

li {
    list-style-type: none;
}

.h1, .h2, .h3, .h4, h1, h2, h3, h4, p {
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

a {
    color: $color-text;
    text-decoration: none;
    &:hover {
        color: $color-general;
        text-decoration: none;
    }
}

a:focus, button:focus, input:focus, textarea:focus {
    outline: 0;
    text-decoration: none;
}

// Базовая обертка сайта
.base {
    &-wrapper, &-container {
        width: 100%;
    }
    
    &-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    &-container {
        flex: 1  auto;
        overflow: initial;
    }

    &-page {
        padding-bottom: 90px;
    }
}

.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.navbar {
    padding: 0;
}

.params-item~.params-delimeter {
    display: none;
}

.params-delimeter::before {
    content: '-';
}
.page-item:first-child .page-link{
    border-radius: 0;
}
.page-item:last-child .page-link{
    border-radius: 0;
}
.page-item .page-link{
    outline: none;
    box-shadow: none;
}

//  
.mobile-search-trigger{
	svg{
		pointer-events: none;
	}
}
.nav-close{
	*{
		pointer-events: none;
	}
}



///  Bootstrap reset
.btn-primary{
	background-color: $color-general;
    border-color: $color-general;
}
.btn-primary:hover,
.btn-outline-primary:hover,
.page-item.active .page-link{
	background-color: $color-bootstrap-dark;
}
.btn-outline-primary,
.btn-primary:hover,
.btn-outline-primary:hover,
.page-item.active .page-link{
	border-color: $color-bootstrap-dark;
}
.btn-outline-primary,
.page-link,
.page-link:hover{
	color: $color-bootstrap-dark;
}
.btn.disabled, .btn:disabled{
	opacity: 1;
}

@media (min-width: 1200px){
    .container {
        max-width: 1200px;
    }
}
@media (max-width: 1200px){
    .container {
        max-width: calc(100% - 30px);
        padding: 0;
    }
}