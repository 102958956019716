.form {
    border: 1px solid #ddd;
    background-color: #fff;
    max-width: 670px;
    max-width: 100%;
    padding: 20px 30px 35px;

    &-title {
        display: block;
        font-weight: 700;
        font-size: 20px;
        color: $color-text;
        border-bottom: 1px solid #d8d8d8;
        padding: 0 0 10px;
        margin: 0 0 16px;
    }

    &-group {
        &__title {
            margin-bottom: 5px;
        }
	}
	
	.acceptance_checkbox{
		margin: 10px 0;
	}

    &.load{
        display: block;
        position: relative;
        .load{
            display: block;
            background-color: rgba(255,255,255,0.95);
        }
    }

    .load{
        img{
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 50px;
            height: 50px;
        }
    }

    .load{
        display: none;
        position: absolute;
        z-index: 200;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.load {
    display: none;
}