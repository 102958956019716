.product-card{
    border: 1px solid $color-border;
    height: 360px;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    transition: $time;
    position: relative;
    margin-bottom: 30px;
    &__thumb{
        height: 180px;
        padding: 0 10px;
        img{
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-position: center;
        }
        &-badges{
            position: absolute;
            left: 10px;
            top: 10px;
            span{
                border-radius: 0;
                margin-right: 5px;
            }
        }
        &.product-card__thumb-empty{
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                fill: $color-general;
            }
        }
    }
    &__body{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0 16px;
        margin: 0 15px;
        border-top: 1px solid $color-border;
        &-tite{
            font-size: 15px;
            line-height: 21px;
            color: $color-text;
            transition: $time;
        }
        &-price{
            b{
                font-size: 18px;
                color: $color-text;
            }
            span{
                font-size: 14px;
                color: $color-text;
                text-decoration: line-through;
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
    &__footer{
        padding: 0 15px 18px;
    }
    &__add{
        width: 100%;
        height: 38px;
        outline: none;
        border: none;
        transition: $time;
        &:hover{
            background-color: $color-primary;
            cursor: pointer;
        }
    }
    &__nutify{
        width: 100%;
        height: 40px;
        background-color: transparent;
        border: 1px solid $color-general;
        color: $color-general;
        transition: $time;
        &:hover{
            background-color: $color-general;
            color: #fff;
            cursor: pointer;
        }
    }
    &:hover{
        border-color: transparent;
        box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.186141);
        .product-card__body-tite{
            color: $color-general;
        }
    }
}