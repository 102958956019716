.article-card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #E5E5E5;
    height: 370px;
    transition: $time;
    margin-bottom: 15px;
    &__thumb{
        min-height: 180px;
        position: relative;
        display: flex;
        flex: 55%;
        justify-content: center;
        align-items: center;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
        svg{
            fill: $color-general;
        }
    }
    &__body{
        flex: 1 0 auto;
        padding: 15px 0px;
        margin: 0 19px;
        border-top: 1px solid $color-border;
        b{
            font-size: 18px;
            line-height: 19px;
            color: $color-text;
            transition: $time;
        }
        p{
            font-size: 15px;
            line-height: 22px;
            color: $color-text;
        }
    }
    &__footer{
        padding: 0 18px 18px;
        p{
            font-size: 14px;
            color: $color-general;
            background-image: url('../images/calendar.png');
            background-position: 0 2px;
            background-size: auto;
            background-repeat: no-repeat;
            padding-left: 20px;
        }
    }
    &:hover{
        border-color: #E4E8F4;
        box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.186141);
        b{
            color: $color-general;
        }
    }
}