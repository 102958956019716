.category-card{
	display: block;
	position: relative;
	width: 375px;
	height: 445px;
	overflow: hidden;
	div{
		width: 100%;
		height: 100%;
		background-size: cover;
		padding: 40px 30px;
		display: flex !important;
    	align-items: flex-end;
    	transition: $time;
	}
	&::before{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: block;
		background-color: $color-primary;
		opacity: 0.7;
		transition: $time;
	}
	p{
		font-weight: 900;
		font-size: 22px;
		line-height: 28px;
		color: #FFFFFF;
	    z-index: 9;
    	position: relative;
    	transition: $time;
	}
	svg.arrow{
		width: 37px;
		height: 18px;
		fill: #fff;
	    z-index: 9;
    	position: absolute;
    	transition: $time;
    	left: -37px;
    	bottom: 46px;
	}
	svg.no-photo{
		width: 100%;
		position: absolute;
		left: 0;
    	top: calc(50% - 70px);
	}
	&:hover{
		div{
			padding-bottom: 90px;
		}
		&::before{
			width: calc(100% - 20px);
			height: calc(100% - 20px);
			left: 10px;
			top: 10px;
			background-color: $color-general;
		}
		svg.arrow{
			left: 30px;
		}
	}
}
// calc()

@media (max-width: 768px) {
	.category-card{
		height: 280px;
		div{
		    background-position: center;
		}
	}
}

@media (max-width: 425px){
	.category-card {
	    height: 200px;
	    div{
	    	padding: 40px 60px;
	    }
	}
}
