.page-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 12px 0 26px;
    h1{
        font-weight: 900;
        font-size: 40px;
        line-height: 42px;
        color: #203650;
    }
    span{

    }
    &_small {
        font-size: 20px;
        line-height: 22px;
        padding: 0 0 16px;
    }
    &-link {
        height: max-content;
        position: relative;
        top: 5px;
        margin-left: 15px;
        background-color: #0E53A0;
        color: #FFF;
        padding: 6px 18px;
        font-size: 12px;
        border: 1px solid #0E53A0;
        transition: .2s;

        &:hover {
            background-color: #FFF;
            color: #0E53A0;
        }
    }
}

@media (max-width: 565px) {
	.page-title {
		line-height: 32px;
		padding: 5px 0 15px;
        font-size: 26px;
        
        &_small {
            font-size: 18px;
            line-height: 20px;
        }
	}
}
