.mobile-menu{
	&, & .sub-menu{
		position: fixed;
		z-index: 1005;
		left: 0;
		top: 60px;
		width: 100%;
		//height: 100%;
		height: calc(100% - 60px);
		overflow-y: scroll;
		background-color: #fff;
		left: 110%;
		transition: .3s;
		&.opened{
			left: 0;
		}
	}
	.main-menu{
		background-color: #f5f5f5;
	}
	.catalog-button__link{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        width: 100%;
        height: 50px;
        padding: 0 15px;
        &-lines{
            width: 13px;
            height: 2px;
            display: block;
            position: relative;
            top: -1px;
            background-color: $color-general;

            &::after, &::before{
                content: '';
                display: block;
                position: relative;
                width: 13px;
                height: 2px;
                background-color: $color-general;

            }
            &::before{
                top: -5px;
            }

            &::after{
                bottom: -3px;
            } 
        }
        &-title{
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.8px;
            text-transform: uppercase;
            color: $color-text;
            margin-left: 8px;
        }
    }
	.main-menu, .sub-menu{
		> li{
			> a{
				display: block;
				position: relative;
				padding: 17px 50px 17px 20px;
				font-weight: 700;
			    font-size: 12px;
			    line-height: 14px;
			    text-align: left;
			    letter-spacing: 0.685714px;
			    text-transform: uppercase;
			    color: #595E6C;
				border-bottom: 1px solid #dee2e6;
				.open-child{
					&:after{
						border-left-color: #999;
					}
				}
			}
		}
	}
	&__footer{
		padding: 15px;
		.theme-addresses-btn{
			width: 270px;
			height: 50px;
		}
	}
	.open-child{
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		width: 50px;
		height: 100%;
		transform: rotate(180deg);
		> svg{
			fill: #999;
			margin: 0 8px 2px 0;
			width: 16px;
			height: 16px;
		}
	}
	.step_back{
		background-color: #f5f5f5;
		height: 51px;
		> svg{
			fill: #999;
			margin: 0 8px 2px 0;
			width: 16px;
			height: 16px;
		}
	}
}

@media (max-width: 390px) {
  .mobile-menu {
		&, & .sub-menu {
			height: calc(100% - 50px);
		}
	}
}