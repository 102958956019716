.notification_block{
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 130px;
	margin: 0 0 30px;
	padding: 15px 40px;
	box-shadow: 0 2px 7px rgba(0, 0, 0, 0.14);
	background-color: #fff;
	.wrapper_left{
		display: flex;
		.icon_wrapper{
			display: flex;
			align-items: center;
			width: 100px;
			min-width: 100px;
			> svg{
				fill: #203650;
				&.gray{
					fill: #c0c0c0;
				}
			}
		}
		.text{
			b{
				display: block;
				color: #203650;
				font-size: 24px;
				font-weight: 700;
			}
			span{
				display: block;
				color: #9b9b9b;
				font-size: 16px;
				font-weight: 400;
			}
			a{
				color: $color-general;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
}

@media (max-width : 1199px) {
	.notification_block{
		.text{
			padding:0 10px;
		}
	}
}

@media (max-width : 767px) {
	.notification_block{
		align-items: flex-start;
		height: auto;
		flex-direction: column;
		padding: 25px 20px;
		margin-bottom: 15px;
		.wrapper_left{
			flex-direction: column;
			.text{
				padding: 15px 0;
				b{
					font-size: 24px;
					line-height: 30px;
				}
				span{
					line-height: 22px;
    				padding-top: 5px;
				}
			}
		}
		
	}
}