.page-menu{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px 10px;
	&__link{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 35px;
		padding: 5px 15px;
		margin: 0 10px 10px;
		color: #0E53A0;
		font-size: 16px;
		line-height: 21px;
		border: 1px solid #0E53A0;
		background-color: #fff;
		transition: .3s;

		&.active, &:hover{
			background-color: #0E53A0;
			color: #fff;
		}
	}
}

@media(max-width: 767px){
	.page-menu{
		margin: 0 -5px -5px;
		&__link{
			height: 30px;
			padding: 5px 10px;
			margin: 0 5px 10px;
			font-size: 14px;
		}
	}
}