// General fonts
@mixin font-h1 {
	font-weight: 900;
	font-size: 36px;
	line-height: 42px;
	color: $color-primary;
}

@mixin font-h2 {
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;
	color: $color-primary;
}

@mixin font-h3 {
	color: $color-primary;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
}

@mixin font-p {
	font-size: 16px;
	line-height: 22px;
	color: $color-text;;

}

@mixin font-sign {
	color: #999;
	font-size: 14px;
	font-weight: 400;
}



