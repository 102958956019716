.load_content{
	display: block;
	margin: 0 0 30px;
	width: 100%;
	height: 50px;
	font-size: 20px;
	font-weight: 400;
	border-radius: 0;
}
@media (max-width: 767px) {
  
	.load_content {
		margin: 0 0 15px;
		height: 40px;
		font-size: 16px;
	}
}