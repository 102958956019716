.product{
	&__top-info {
		padding: 0 0 44px;
	}
}

.product-tabs{
	.nav-tabs {
		.nav-item{
			a{
				border-radius: 0;
				&.active{
					color: $color-general;
				}
			}
		}
	}
}
@media (max-width: 767px) {
	.product-tabs{
		.nav-tabs {
			.nav-item{
				a{
					&.active{
						background: $color-general;
						color: #fff;
					}
				}
			}
		}
	}
}

@media (max-width: 575px) {
	.product{
		&__top-info {
			padding: 0 0 15px;
		}
	}
}