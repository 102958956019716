.contacts-block {
	padding: 0 0 30px;
	&__title {
		display: block;
		padding: 0 0 16px;
		color: #373a3c;
		font-size: 18px;
		font-weight: 600;
	}
	&__info {
		padding: 20px 0 0;
	}
	&__map-container {
		// height: 400px;
		height: 100%;
		min-height: 400px;
		@media(max-width: 1200px){
			margin-top: 25px;
			max-height: 400px;
		}
	}
}

.contacts-yandex-link{
	font-size: 15px;
    line-height: 15px;
}

.contacts-group{

}
.contacts-tab{
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: #D0D6E7;
	padding: 20px 30px;
	margin-bottom: 30px;
	&__title{
		font-size: 12px;
		color: #333333;
		letter-spacing: 0.685714px;
		text-transform: uppercase;
		font-weight: 700;
	}
	&__arrow{
		width: 15px;
		height: 15px;
		fill: #203650;
		transition: .2s;
		&.transformed-arrow{
			transform: rotate(180deg);
		}
	}
}

.contacts-card{
	border: 1px solid #959EB7;
	margin: 0 15px 30px;
	width: 370px;
	padding: 20px 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	&__container{
		display: none;
		&.shown{
			display: block;
		}
	}
	&__wrap{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: -15px;
		width: calc(100% + 30px);
	}
	&__header{
		padding: 0 0 30px;
	}
	&__name{
		font-size: 18px;
		color: #0E53A0;
		padding: 0 0 5px;
		line-height: 24px;
	}
	&__position{
		font-size: 14px;
		color: #7C8294;
		line-height: 22px;
	}
	&__phones{
		display: flex;
		flex-direction: column;
		padding: 0 0 12px;
	}
	&__phone{
		font-size: 16px;
		color: #333333;
		line-height: 22px;
	}
	&__emails{
		padding: 0 0 15px;
	}
	&__mail{
		font-size: 16px;
		color: #0E53A0;
		line-height: 22px;
		display: inline-flex;
    	border-bottom: 1px solid;
	}
	&__text{
		p{
			font-size: 16px;
			color: #333333;
			line-height: 22px;
		}
	}
}


@media (max-width: 767px) {
	.contacts-block {
		padding: 0 0 20px;
		&__map-container {
			height: 230px;
		}
	}

	.contacts-tab{
		padding: 15px !important;
		margin-bottom: 15px !important;
	}
	.contacts-card{
		margin-bottom: 15px !important;
	}
}
