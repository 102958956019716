.index-row{
	
	// Дилеры
	&.dealer{
		padding: 5px 0;
		background: #d0d6e7;
		h1{
			letter-spacing: 0.685714px;
			text-transform: uppercase;
			color: #595E6C;
			font-weight: 700;
			font-size: 12px;
			line-height: 14px;
    	}
		.dealer-title{
			font-size: 14px;
			line-height: 18px;
			color: $color-placeholder;
			margin-bottom: 10px;
		}
		.dealer-slider{
			border: 1px solid $color-border;
			height: 90px;
			&__slide{
				padding: 6px 0;
			    position: relative;
			    background-color: #fff;
			    height: 100%;
				img{
				    border-right: 1px solid $color-border;
				    width: 100%;
			        height: 100%;
				    object-fit: cover;
				    object-position: center;
				}
			}
			.slick-list,
			.slick-track,
			.slick-track div{
				height: 100%;
			}
			.slick-arrow{
				position: absolute;
				top: calc(50% - 25px);
				background-color: transparent;
				border: 1px solid #E9E9E9;
				svg{
					fill: #C1C1C1;
				}
				&:hover{
					background-color: $color-general;
					svg{
						fill: #fff;
					}
				}
				&.slick-disabled{
					opacity: 0.5;
				}
				&.slick-disabled:hover{
					background-color: transparent;
					cursor: inherit;
					svg{
						fill: #C1C1C1;
					}
				}
			}
			.prevArrow{
				left: -95px;
			}
			.nextArrow{
				right: -95px;
				svg{
				    margin-left: 5px;
				}
			}
		}
		.slick-dots li button{
			border-color: #dedede;
			opacity: 1;
		}
		.slick-dots li.slick-active{
			border-color: $color-general;
		}
	}

	// Категории
	&.category{
		padding: 21px 10px 54px;
		.slick-arrow{
			background-color: $color-general;
			position: absolute;
			top: calc(50% - 25px);
			z-index: 99;
			&:hover{
				background-color: $color-primary;
			}
			&.slick-disabled{
				background-color: #dee2e6;
				cursor: inherit;
				svg{
					fill: $color-general;
				}
				&:hover{
					background-color: #dee2e6;
					cursor: inherit;
				}
			}
		}
		.prevArrow{
			left: -10px;
		}
		.nextArrow{
			right: -10px;
		}
		.slick-dots{
			margin-bottom: 5px;
		}
		.slick-dots li button{
			border-color: #dedede;
			opacity: 1;
		}
		.slick-dots li.slick-active{
			border-color: $color-general;
		}
	}

	// Предложения
	&.offers{
	    padding: 0 0 76px;
	}

	// Техника
	&.vechicle{
		.vechicle-container{
		    border: 1px solid #E0E0E0;
		    width: 1240px;
		    margin: -22px auto 0;
		    padding: 48px 0;
		}
		.theme-title{
	    	margin-bottom: 0 !important;
	    }
	}

	// Спец предложения
	&.special{
		padding: 40px 0 0;
		.theme-title p{
			text-align: center;
		}
		.special-slider{
			&__item{
				overflow: hidden;
				height: 160px;
				// margin: 0 10px;
				padding: 0 10px;
				img{
					margin: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.slick-arrow{
				position: absolute;
				top: calc(50% - 50px);
				background-color: transparent;
				border: 1px solid #E9E9E9;
				svg{
					fill: #C1C1C1;
				}
				&:hover{
					background-color: $color-general;
					svg{
						fill: #fff;
					}
				}
				&.slick-disabled{
					opacity: 0.5;
				}
				&.slick-disabled:hover{
					background-color: transparent;
					cursor: inherit;
					svg{
						fill: #C1C1C1;
					}
				}
			}
			.prevArrow{
				left: -85px;
			}
			.nextArrow{
				right: -85px;
				svg{
				    margin-left: 5px;
				}
			}
			.slick-dots{
				padding: 8px 0 0;
			}
			.slick-dots li button{
				border-color: #dedede;
				opacity: 1;
			}
			.slick-dots li.slick-active{
				border-color: $color-general;
			}
		}
	}

	// Клиенты
	&.clients{
	    padding: 66px 0 60px;
		.clients-title{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-bottom: 60px;
			p{
				font-weight: 900;
				font-size: 40px;
				text-align: center;
				color: $color-primary;
				padding: 0 40px;
			}
			span{
				flex: 1;
				height: 1px;
				background-color: #D8D8D8;
			}
		}
		.clients-slider{
			&__item{
				background-position: center;
				background-repeat: no-repeat;
				width: 95px;
				height: 95px;
				img{
					margin: 0 auto;
				}
			}
			.slick-arrow{
				position: absolute;
				top: calc(50% - 25px);
				background-color: transparent;
				border: 1px solid #E9E9E9;
				svg{
					fill: #C1C1C1;
				}
				&:hover{
					background-color: $color-general;
					svg{
						fill: #fff;
					}
				}
				&.slick-disabled{
					opacity: 0.5;
				}
				&.slick-disabled:hover{
					background-color: transparent;
					cursor: inherit;
					svg{
						fill: #C1C1C1;
					}
				}
			}
			.prevArrow{
				left: -85px;
			}
			.nextArrow{
				right: -85px;
				svg{
				    margin-left: 5px;
				}
			}
			.slick-dots li button{
				border-color: #dedede;
				opacity: 1;
			}
			.slick-dots li.slick-active{
				border-color: $color-general;
			}
		}
	}

	// Новости
	&.article{
		background-color: #E4E8F4;
		padding: 40px 0 61px;
		.article-title{
			display: flex;
			justify-content: space-between;
			align-items: center;
		    margin-bottom: 29px;
			p{
				font-weight: 900;
				font-size: 40px;
				color: $color-primary;
			}
			a{
				display: inline-block;
				border-bottom: 1px solid $color-general;
				font-weight: 300;
				font-size: 16px;
				color: #8F8F8F;
				line-height: 18px;
			}
		}
		.mobile-more{
			display: none;
			a{
				display: inline-block;
				border-bottom: 1px solid $color-general;
				font-weight: 300;
				font-size: 16px;
				color: #8F8F8F;
				line-height: 18px;
			}
		}
	}

	// сео
	&.seo{
	    padding: 0 0 56px;
		background-color: #E4E8F4;
		.seo-scroller__image,
		.seo-scroller__content{
			height: 565px;
		}
		.os-scrollbar{
			width: 8px;
		}
		.os-scrollbar-track{
			background-color: #FFFFFF;
		}
		.os-scrollbar-handle{
			max-height: 100px;
			background-color: #156DBC !important;
		    border: 3px solid #156DBC !important;
    		left: -1px;
    		border-radius: 0 !important;
		}
		.content-text{
			padding-right: 30px;
		}
	}

}

@media (max-width: 1365px) {
	// .index-row.dealer {
	//     padding: 20px 0 45px;
	// }
	.index-row.vechicle .vechicle-container{
		width: auto;
	}
}
@media (max-width: 1199px) {
	// .index-row.dealer {
	//     padding: 20px 15px 45px;
	// }
}
@media (max-width: 991px) {
	.index-row.article {
	    background-color: #E4E8F4;
	    padding: 15px 0 10px;
	}

	// .index-row.dealer h1{
    //     font-size: 48px !important;
    //     line-height: 48px !important;
    //     margin-bottom: 20px !important;
    // }
}
@media (max-width: 576px) {
	.index-row.category {
	    padding: 21px 10px 16px;
	}
	.index-row.offers {
	    padding: 0 0 20px;
	}
	.index-row.clients .clients-title{
		margin-bottom: 20px;
	}
	.index-row.clients .clients-title p,
	.index-row.article .article-title p{
		font-size: 28px;
    	line-height: 36px;
    	margin-top: 0;
    	text-align: center;
	}
	.index-row.clients {
	    padding: 30px 0 30px;
	}
	.index-row.clients .clients-slider__item{
		height: 70px;
	}
	.index-row.clients .clients-slider__item img{
		height: 100%;
		object-fit: contain;
	}
	.index-row.article{
		padding: 10px 015px;
	}
}
@media (max-width: 425px) {
	// .index-row.dealer h1{
    //     font-size: 36px !important;
    //     line-height: 34px !important;  
    // }
	.index-row.dealer .dealer-slider a{
		padding: 0;
	}
	.index-row.dealer .dealer-slider{
		height: 55px;
	}
	.index-row.dealer .dealer-slider a img{
		object-fit: contain;
	}
	.index-row.article .article-title{
		flex-direction: column;
		margin-bottom: 8px;
	}
	.index-row.article .article-title a{
		display: none;
	}
	.mobile-more{
	    display: block !important;
    	text-align: center;
    	margin-bottom: 35px;
	}
}


