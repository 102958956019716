.wrapper-404{
    text-align: center;
	padding: 34px 0;
	&__error-code{
		color: #ccc;
		font-size: 170px;
    	line-height: 200px;
		align-items: center;
	}
	&__title{
	    display: block;
	    padding: 0 30px 8px;
	    text-align: center;
	    font-weight: 900;
	    font-size: 36px;
	    line-height: 36px;
	    color: #203650;
	}
	&__desc{
		display: block;
		color: #373a3c;
		font-size: 16px;
		font-weight: 400;
		line-height: 25px;
	}
	&__to-index{
		margin: 34px auto 0;
		width: 240px;
		height: 55px;
		border: none;
		transition: $time;
		&:hover{
			background-color: $color-primary;
			color: #fff;
		}
	}
}


@media (max-width : 767px) {
	.wrapper-404{
		padding: 20px 0 0;
		&__title{
			font-size: 26px;
    		line-height: 44px;
		}
		&__to-index{
			margin: 20px 0 30px;
		}
		&__error-code{
			font-size: 130px;
    		line-height: 110px;
		}
	}
}