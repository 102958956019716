.zoom-in{
	width: 45px;
	height: 45px;
	position: absolute;
	top: 10px;
    right: 10px;
	background-color: #0E53A0;
	opacity: 0.3;
	border-radius: 50%;
	background-image: url('../images/zoom-in.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 20px;
	transition: $time;
}
.product-slider{
	&:hover{
		.zoom-in{
			opacity: 1;
		}
	}
	&__container{
		position: relative;
		border: 1px solid #e5e5e5;
		background-color: #fff;
		overflow: hidden;
	}
	&__container-slide{
		display: flex !important;
		align-items: center;
		justify-content: center;
		padding: 15px;
		height: 380px;
		outline: none;
		// &:hover{
		// 	&:after{
		// 		background-color: $color-general;
		// 	}
		// }
		img{
			max-width: 100%;
			max-height: 100%;
		}
		// &:after{
		// 	content:"";
		// 	position: absolute;
		// 	right: 16px;
    	// 	top: 16px;
		// 	width: 44px;
		// 	height: 44px;
		// 	border-radius: 100%;
		// 	background-repeat: no-repeat;
		// 	background-position: center center;
		// 	background-color: rgba(1, 116, 216, 0.3);
		// 	background-size: 19px 19px;
		// 	background-image: $whiteZoomIcon;
		// 	transition: .3s;
		// }
	}
	&__carousel{
		padding-top: 5px;
		overflow: hidden;
		.slick-list{
			margin: 0 -3px;
			.slick-track{
				min-width: 100%;
			}
		}
		.slick-slide{
			margin: 0 3px;
			&.slick-current{
				.product-slider__carousel-item{
					border-color: $color-general;
				}
			}
		}
	}
	&__carousel-item{
		display: flex !important;
		align-items: center;
		justify-content: center;
		height: 94px;
		padding: 5px;
		outline: none;
		border-radius: 4px;
		border: 1px solid #e5e5e5;
		background-color: #fff;
		img{
			max-width: 100%;
			max-height: 100%;
		}
	}
	.slick-dots{
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: 7px;
		width: 100%;
		li{
			&.slick-active{
				button{
					background-color: $color-general;
				}
			}
			button{
				width: 11px;
				height: 11px;
				border-radius: 100%;
				font-size: 0;
				border: none;
				box-shadow: none;
				outline: none;
				margin: 0 6px;
				padding: 0;
				background-color: #dbdbdb;
			}
		}
	}
}

@media (max-width : 767px) {
	.product-slider{
		&__container{
			&:after{
				display: none;
			}
		}
		&__container-slide{
			height: 320px;
		}
		&__carousel{
			display: none;
		}
	}
}

@media (max-width : 575px) {
	.product-slider{
		&__container{
			&:after{
				
			}
		}
		&__container-slide{
			height: 260px;
		}
		&__carousel{
			
		}
	}
}